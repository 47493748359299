import * as $ from "jquery";

/***** common function *****/
var windowW = $(window).width();
var windowH = $(window).height();
$(window).on("resize", function () {
  windowW = $(window).width();
  windowH = $(window).height();
});

/***** responsive guide ******/
var pcWindow, tbWindow, spWindow;
if (1023 < windowW) {
  pcWindow = true;
  tbWindow = false;
  spWindow = false;
} else if (767 < windowW) {
  pcWindow = false;
  tbWindow = true;
  spWindow = false;
} else {
  pcWindow = false;
  tbWindow = false;
  spWindow = true;
}

// -----------------------------------------------------------------
// 共通
// -----------------------------------------------------------------
// メニューナビゲーション
const $headerOpenBtn = $(".header-open-btn");
const $navMenu = $(".nav-header");
const $headerMenuCloseBtn = $(".menu-close-btn");

$headerOpenBtn.on("click", function () {
  $navMenu.fadeToggle();
});
$headerMenuCloseBtn.on("click", function () {
  $navMenu.fadeToggle();
});

// 管理者ナビ
const $adminNavList = $(".admin-nav-list span");
$adminNavList.on("click", function () {
  $(this).next().slideToggle();
});

// 製品画像の拡大
const $imageOpenBtn = $(".image-open-btn");
$imageOpenBtn.on("click", function () {
  $(".product-large-image").slideToggle();
});
const $imageCloseBtn = $(".image-close-btn");
$imageCloseBtn.on("click", function () {
  $(".product-large-image").slideToggle();
});


